<template>
  <div>
    <existing-supply-chart
      :identifier="chartIdentifier(1)"
      :province="currentProvince"
    />
    <!--</chart-box>-->
    <chart-box
      chartType="bar"
      :identifier="chartIdentifier(2)"
      title="ศักยภาพพลังงานทดแทน กลุ่ม Alternative Supply (ktoe)"
      tooltip="ศักยภาพพลังงานทดแทนกลุ่ม Alternative Supply คือ ศักยภาพทางเลือกของพลังงานทดแทน ที่เกิดขึ้นจากศักยภาพทางธรรมชาติ ที่เมื่อติดตั้งอุปกรณ์ผลิตพลังงานแล้วก่อให้เกิดการเปลี่ยนแปลงการใช้ประโยชน์ที่ดินในปัจจุบัน"
      :fetchPromise="fetchAlternativeSupply"
    >
    </chart-box>
    <chart-box
      chartType="bar"
      :identifier="chartIdentifier(3)"
      title="ศักยภาพการอนุรักษ์พลังงาน (ktoe)"
      helperText="หมายเหตุ:  ข้อมูลประสิทธิภาพพลังงาน เป็นข้อมูลจริงจากการดำเนินโครงการด้านการอนุรักษ์พลังงานของแต่ละจังหวัด"
      :fetchPromise="fetchEnergyEfficiency"
    >
    </chart-box>
  </div>
</template>

<script>
import ExistingSupplyChart from '@/components/ExistingSupplyChart.vue';
import ChartBox from '@/components/ChartBox.vue';
import { get, isEmpty, head, range, forIn, find, toUpper, has } from 'lodash';
import { mapState, mapGetters, mapMutations, mapActions } from 'vuex';

export default {
  name: 'energy-capability',

  components: {
    ExistingSupplyChart,
    ChartBox,
  },

  data() {
    return {
      baseUrl: process.env.VUE_APP_BACKEND_URL,
      topicNumber: '2.3',
      existingSupply: {
        show: false,
      },
      alternativeSupply: {
        show: false,
      },
      energyEfficiency: {
        show: false,
      },
      graphData: {
        title: 'พลังงานทดแทน',
        unit: ' ktoe',
        data: [
          ['Renewables', 'ktoe'],
          ['Biomass heat', 1203],
          ['Solar hotwater', 17],
          ['Geothermal heat', 12],
          ['Hydropower', 341],
          ['Ethanol', 50],
          ['Biodiesel', 17],
          ['Biomass electricity', 28],
          ['Wind power', 51],
          ['Geothermal electricity', 7],
          ['Solar PV power', 6],
          ['Solar CSP', 0.2],
          ['Ocean power', 0.1],
        ],
      },
    };
  },

  created() {},

  computed: {
    ...mapGetters('peno', ['currentProvince']),
    ...mapGetters(['latestNationalPlan']),
    currentYear() {
      return this.$options.filters.thai_year(this.latestNationalPlan.year);
    },
  },

  methods: {
    ...mapActions('mondas', ['fetchPreREEEPotential', 'fetchREEEPotential']),
    chartIdentifier(number) {
      return this.topicNumber + '.' + number;
    },
    fetchExistingSupply() {
      return this.fetchPreREEEPotential({
        province: this.currentProvince,
        key: 'RDREEEP_PVroofPotExTot',
      }).then((data) => {
        if (data) {
          const generalData = data instanceof Array ? head(data) : data;
          const targetYear = this.getTargetYear(generalData);
          return this.fetchREEEPotential({
            year: targetYear,
            province: this.currentProvince,
          }).then((data) => {
            const title = 'Existing Supply (ktoe) ปี ' + targetYear;
            const result = [[title, 'ศักยภาพที่แท้จริง', 'ศักยภาพคงเหลือ']];

            result.push([
              'แสงอาทิตย์ติดตั้งบนหลังคา',
              this.getDataValueInsensitive(data, 'RDREEEP_PVroofPotExTot', 0),
              this.getDataValueInsensitive(data, 'RDREEEP_PVroofPotExRem', 0),
            ]);
            result.push([
              'ก๊าซชีวภาพ',
              this.getDataValueInsensitive(data, 'RDREEEP_BiogasPotExTot', 0),
              this.getDataValueInsensitive(data, 'RDREEEP_BiogasPotExRem', 0),
            ]);
            result.push([
              'ขยะ',
              this.getDataValueInsensitive(data, 'RDREEEP_WastePotExTot', 0),
              this.getDataValueInsensitive(data, 'RDREEEP_WastePotExRem', 0),
            ]);
            result.push([
              'ชีวมวล',
              this.getDataValueInsensitive(data, 'RDREEEP_BiomassPotExTot', 0),
              this.getDataValueInsensitive(data, 'RDREEEP_BiomassPotExRem', 0),
            ]);
            result.push([
              'เอทานอล',
              this.getDataValueInsensitive(data, 'RDREEEP_EthPotExTot', 0),
              this.getDataValueInsensitive(data, 'RDREEEP_EthPotExRem', 0),
            ]);
            result.push([
              'ไบโอดีเซล',
              this.getDataValueInsensitive(
                data,
                'RDREEEP_BiodieselPotExTot',
                0
              ),
              this.getDataValueInsensitive(
                data,
                'RDREEEP_BiodieselPotExRem',
                0
              ),
            ]);
            const chartData = {
              title: title,
              unit: generalData.unit,
              data: result,
            };
            return chartData;
          });
        }
      });
    },
    fetchAlternativeSupply() {
      return this.fetchPreREEEPotential({
        province: this.currentProvince,
        key: 'RDREEEP_WindPotAlt',
      }).then((data) => {
        if (data) {
          const generalData = data instanceof Array ? head(data) : data;
          const targetYear = this.getTargetYear(generalData);
          return this.fetchREEEPotential({
            year: targetYear,
            province: this.currentProvince,
          }).then((data) => {
            const title = 'Alternative Supply (ktoe) ปี ' + targetYear;
            const result = [['แหล่งพลังงาน', 'ศักยภาพที่แท้จริง']];
            result.push([
              'ลม',
              this.getDataValueInsensitive(data, 'RDREEEP_WindPotAlt', 0),
            ]);
            result.push([
              'แสงอาทิตย์บนพื้นดิน',
              this.getDataValueInsensitive(data, 'RDREEEP_PvgroundPotAlt', 0),
            ]);
            result.push([
              'พืชพลังงาน (หญ้าเนเปียร์)',
              this.getDataValueInsensitive(data, 'RDREEEP_NapierPotAlt', 0),
            ]);
            result.push([
              'ไม้โตเร็ว (ยูคาลิปตัส)',
              this.getDataValueInsensitive(data, 'RDREEEP_EucalPotAlt', 0),
            ]);
            const chartData = {
              title: title,
              unit: generalData.unit,
              data: result,
            };
            return chartData;
          });
        }
      });
    },
    fetchEnergyEfficiency() {
      return this.fetchPreREEEPotential({
        province: this.currentProvince,
        key: 'RDREEEP_EffRes',
      }).then((data) => {
        if (data) {
          const generalData = data instanceof Array ? head(data) : data;
          const targetYear = this.getTargetYear(generalData);
          return this.fetchREEEPotential({
            year: targetYear,
            province: this.currentProvince,
          }).then((data) => {
            const title = 'ศักยภาพการอนุรักษ์พลังงาน (ktoe) ปี ' + targetYear;
            const result = [['สาขา', 'ศักยภาพการอนุรักษ์พลังงาน']];

            result.push([
              'ครัวเรือน',
              this.getDataValueInsensitive(data, 'RDREEEP_EffRes', 0),
            ]);
            result.push([
              'อุตสาหกรรม',
              this.getDataValueInsensitive(data, 'RDREEEP_EffInd', 0),
            ]);
            result.push([
              'ธุรกิจ',
              this.getDataValueInsensitive(data, 'RDREEEP_EffCom', 0),
            ]);
            const chartData = {
              title: title,
              unit: (generalData || []).unit,
              data: result,
            };
            return chartData;
          });
        }
      });
    },
    getTargetYear(generalData) {
      let currentYearInt = parseInt(this.currentYear);
      let targetYear = currentYearInt;
      if (generalData) {
        let minYear = generalData.min_year;
        let maxYear = generalData.max_year;
        if (currentYearInt < minYear) {
          targetYear = minYear;
        } else if (currentYearInt > maxYear) {
          targetYear = maxYear;
        }
      }
      return targetYear;
    },
    json(params) {
      return 'data=' + encodeURIComponent(JSON.stringify(params));
    },
    getDataValueInsensitive(object, key, default_value = 0) {
      const upper = toUpper(key);
      if (has(object, key)) {
        return get(object, `${key}.value`, default_value);
      } else if (has(object, upper)) {
        return get(object, `${upper}.value`, default_value);
      }
      return default_value;
    },
  },
};
</script>

<style scoped></style>
